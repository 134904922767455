mcWeb.bidBanner = new (function($) {
     'use strict';
    var self = this;
    self.daysTxt = 'DAY(S)';
    self.hoursTxt = 'HR(S)';
    self.minutesTxt = 'MIN(S)';
    self.secondsTxt = 'SEC(S)';
    self.startCountdown = function(bidId, days, hours, minutes, seconds){
        if(bidId === "")
            return;
        self.daysTxt = days;
        self.hoursTxt = hours;
        self.minutesTxt = minutes;
        self.secondsTxt = seconds;

        var banner = $("div[data-bid-id="+bidId +"]");
        var dateExpiry = banner.data("bid-end");
        var dateStart = banner.data("bid-start");
        var bidStarted = banner.data("bid-started");
        var bidEnded = banner.data("bid-expired");

        var startDate =  new Date(dateStart).getTime();
        var endDate = new Date(dateExpiry).getTime();

        var now = new Date().getTime();
        if(startDate > now){
            bidStarted = false;
        }
        else{
            bidStarted = true;
        }

        if(endDate > now){
            bidEnded = false;
        } else{
            bidEnded = true;
        }

        if(bidEnded === true && bidStarted === true){
            //show msg saying bid has ended
            banner.find(".banner-bid-ended").removeClass("hide");
        }
        else{
            if(bidStarted === true){
                //show countdown to when the sale ends
                self.runCountdown(banner, banner.find(".banner-bid-countdown"),  new Date(dateStart).getTime(), new Date(dateExpiry).getTime());
            }
            else{
                //sale not started and not ended. Hence we can set a countdown to when it starts
                self.runCountdown(banner, banner.find(".banner-bid-countdown"), new Date(dateStart).getTime(),new Date(dateExpiry).getTime());
            }
        }

    };

    self.runCountdown = function(banner, salesCountdown, startDate, endDate){
        var timer = setInterval(function() {
            var now = new Date().getTime();
            salesCountdown.removeClass("hide");
            
            var started;
            if(startDate > now){
                started = false;
            }
            else{
                started = true;
            }
            var bidEnded;
            if(endDate > now){
                bidEnded = false;
            } else{
                bidEnded = true;
            }

            var t;
            if(started)
                t = endDate - now;
            else t = startDate - now;
            var timeFromStart = startDate - now;

            if (t >= 0) {
                var showCountdown = true;
                var days = Math.floor(t / (1000 * 60 * 60 * 24));
                var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                var secs = Math.floor((t % (1000 * 60)) / 1000);
                if(started){
                    if (timeFromStart > 0) {
                        if (banner.find(".banner-bid-ended").hasClass("hide")) {
                            banner.find(".banner-bid-ends-in").removeClass("hide");
                            banner.find(".banner-bid-starts-in").addClass("hide");
                            banner.find(".banner-bid-loading").addClass("hide");
                        }
                    } else {
                        var days2 = Math.floor(timeFromStart / (1000 * 60 * 60 * 24));
                        var hours2 = Math.floor((timeFromStart % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var mins2 = Math.floor((timeFromStart % (1000 * 60 * 60)) / (1000 * 60));
                        var secs2 = Math.floor((timeFromStart % (1000 * 60)) / 1000);

                        if (days2 <= 0 && days2 > -2 && hours2 <= 0 && hours2 > -2 && mins2 <= 0 && mins2 > -2 && secs2 > -5) {
                            //show info that we are waiting for prices to load. It is now less than 5 seconds since sale started. Prices are being loaded on the server, lets wait 5 seconds before showing that is has started
                            showCountdown = false;
                            banner.find(".banner-bid-loading").removeClass("hide");
                            banner.find(".banner-bid-ends-in").addClass("hide");
                            banner.find(".banner-bid-starts-in").addClass("hide");
                        }
                        else {
                            banner.find(".banner-bid-starts-in").addClass("hide");
                            banner.find(".banner-bid-ends-in").removeClass("hide");
                            banner.find(".banner-bid-loading").addClass("hide");
                        }
                    }

                }
                else{

                    if(banner.find(".banner-bid-starts-in").hasClass("hide")){
                        banner.find(".banner-bid-starts-in").removeClass("hide");
                        banner.find(".banner-bid-ends-in").addClass("hide");
                        banner.find(".banner-bid-loading").addClass("hide");
                    }
                }

               

                if(days <=0 && hours <= 0 && mins <= 0 && secs <= 1){
                    setTimeout(self.refreshPriceCache, 4000);
                   setTimeout(self.refreshFields, 5000);
                }
                var countdown = "";

                if (showCountdown) {
                    if (days > 0) {
                        countdown += days + "<span class='label'>" + self.daysTxt +"</span>";
                    }
                    if (hours > 0 && days < 8) {
                        countdown += hours + "<span class='label'>"+ self.hoursTxt + "</span>";
                    }
                    if (mins > 0 && days === 0 && hours < 24 && days < 2) {
                        countdown += mins + "<span class='label'>" + self.minutesTxt +"</span>";
                    }
                    if (days === 0 && hours === 0) {
                        countdown += secs + "<span class='label'>" +self.secondsTxt +"</span>";
                    }
                }
                jQuery(salesCountdown).html(countdown);
            } else {
    //the price for this product should now be invalid
                salesCountdown.removeClass("sale-countdown");
                salesCountdown.data("bid-expires","");
                salesCountdown.html('');
                 if(started){
                     if(banner.find(".banner-bid-ended").hasClass("hide")){
                        banner.find(".banner-bid-ended").removeClass("hide");
                        banner.find(".banner-bid-ends-in").addClass("hide");
                        self.refreshFields();
                     }
                    
                }
            }
        }, 1000);
    }

    self.refreshFields = function(){
            var loadFirstParams = {
                targetKey: ".ajax-field",
                filterTargetKey: "#AttributeListBox",
                onlyUpdateProductList: false,
                doNotRenderFilter: false,
                useDummyData: false,
                popupFilter: 0,
                nextPageNumber: 0
            };
        window.mcWeb.ajaxRenderEngine.fieldsToLoad = [];
        window.mcWeb.ajaxRenderEngine.fieldsToLoad.push(loadFirstParams);
        window.mcWeb.ajaxRenderEngine.runUpdateFields();
    };

    self.refreshPriceCache = function() {
        window.mcWeb.ajaxRenderEngine.refreshPriceCache();

    }
})(jQuery);